<template>
  <b-row>
    <b-col sm="12" md="7" lg="8">
      <b-row>
        <b-col sm="12" md="12" lg="2">
          <img :src="getProductImage($v.form.product.$model)" width="100%" alt=""/>
        </b-col>
        <b-col sm="12" md="12" lg="10">
          <label>{{ $t('product') }}</label>
          <b-row class="justify-content-center">
            <b-col xs="12" md="6">
              <multiselect
                v-model="$v.form.product.$model"
                label="brandName"
                track-by="id"
                open-direction="bottom"
                noResult="''"
                noOptions="''"
                :placeholder="$t('selectProduct')"
                :options="products"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                :show-no-results="false"
                :hide-selected="false"
                @search-change="asyncFind"
                @select="selectProduct"
              />
            </b-col>
            <b-col xs="12" md="6">
              <multiselect
                v-model="$v.form.tagItemPrice.$model"
                label="value"
                track-by="id"
                open-direction="bottom"
                noResult="''"
                noOptions="''"
                :placeholder="$t('selectPacking')"
                :options="tagItemPrices"
                :multiple="false"
                :searchable="false"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                :show-no-results="false"
                :hide-selected="false"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col sm="12" md="5" lg="4">
      <b-row>
        <b-col sm="12" md="6" lg="6">
          <label>{{ $t('direction') }}</label>
          <b-select v-model="$v.form.direction.$model">
            <option value=""></option>
            <option value="0">{{ $t('store.out') }}</option>
            <option value="1">{{ $t('store.in') }}</option>
          </b-select>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          <label>{{ $t('quantity') }}</label>
          <b-form-input v-model="$v.form.quantity.$model" required type="number"/>
        </b-col>
      </b-row>
      <label>{{ $t('price') }}</label>
      <b-form-input v-model="$v.form.price.$model" required type="number"/>
    </b-col>
  </b-row>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { APIService } from '@/services/api';
  import { numeric, integer, minValue, required } from "vuelidate/lib/validators";

  export default {
    components: {
      Multiselect,
    },
    props: {
      index: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        form: {
          product: null,
          tagItemPrice: null,
          barcode: null,
          quantity: null,
          direction: null,
          price: null,
        },
        isLoading: false,
        products: [],
        tagItemPrices: [],
      }
    },
    validations: {
      form: {
        product: {
          required,
        },
        tagItemPrice: {
          required,
        },
        barcode: {
          required,
        },
        quantity: {
          required,
          integer,
          minValue: minValue(1),
        },
        direction: {
          required,
          numeric,
        },
        price: {
          numeric,
          minValue: 0,
        },
      }
    },
    watch: {
      '$v.form.$model': {
        handler: function (value) {
          const data = Object.assign({}, value);
          if (data.quantity) {
            data.quantity = parseInt(data.quantity);
          }
          if (data.price) {
            data.price = parseInt(data.price);
          }
          if (data.quantity && data.direction === "0") {
            data.quantity = -1 * data.quantity;
          }
          delete data.direction;
          if (data.product) {
            if (data.product.tagItems) {
              data.tagItem = {id: data.product.tagItems[0].id};
            }
            data.product = {id: data.product.id};
          }
          if (data.tagItemPrice) {
            data.tagItemPrice = {id: data.tagItemPrice.id};
          }
          console.log('before updateModel: ', data);
          this.$emit('updateModel', {
            index: this.index,
            data,
          });
        },
        deep: true,
      }
    },
    methods: {
      getProductImage(product) {
        if (product && product.images.length > 0) {
          return this.getImageUrl(product.images[0], 'small');
        }
        return '/img/default.jpeg';
      },
      getImageUrl(image, suffix) {
        const imageModificationData = image.modifications.filter(modificationData => modificationData.suffix === suffix);
        if (imageModificationData.length === 0) {
          return '/img/default.jpeg';
        }
        return imageModificationData[0].fileUrl;
      },
      async asyncFind (query) {
        if (query) {
          this.isLoading = true;
          try {
            const products = await APIService.get(`product/autocomplete/${query}`);
            this.products = products;
          }
          catch(e) {
            console.error(e);
          }
          this.isLoading = false
        }
      },
      selectProduct(selectedOption) {
        const tagItemPrices = selectedOption.tagPriceItems;
        this.tagItemPrices = tagItemPrices.map(tagPriceItem => {
          tagPriceItem.value = tagPriceItem.tagItem ? tagPriceItem.tagItem.value : tagPriceItem.id;
          return tagPriceItem;
        });
        // this.value = this.$v.form.$model;
        // this.value.product = selectedOption;
        console.log('Product selected: ', selectedOption);
      },
    },
  }
</script>
