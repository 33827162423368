export const schema = {
  fields: [
    {
      type: 'storeItems',
      model: 'items',
      default: {
        items: [
          {
            product: null,
            tagItemPrice: null,
            barcode: null,
            quantity: null,
            price: null,
          }
        ],
      },
    }
  ]
};
