<template>
  <div v-if="value">
      <b-row v-for="(storeItem, index) in value" :key="index" style="margin-bottom: 5px">
        <b-col
          sm="9"
          md="10"
          lg="10"
        >
          <store-item
            ref="store-item"
            :index="index"
            :storeItem="storeItem"
            @updateModel="updateModel"
          />
        </b-col>
        <b-col
          sm="3"
          md="2"
          lg="2"
        >
          <b-button
            block
            variant="danger"
            @click="removeItem(index)"
            :disabled="value.length < 2"
            style="margin-top: 28px;"
          >
            {{ $t("remove") }} <span class="fa fa-trash"> </span>
          </b-button>
        </b-col>
      </b-row>

      <b-col
        sm="4"
        md="3"
        lg="3"
        offset-sm="8"
        offset-md="9"
        offset-lg="9"
        class="no-padding"
        style="margin-top: 10px"
      >
        <b-button
          block
          variant="success"
          @click="addItem"
        >
          <span class="fa fa-plus"> </span> {{ $t("addPosition") }}
        </b-button>
      </b-col>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { abstractField } from 'vue-form-generator';
  import StoreItem from './StoreItem.vue';

  export default {
      mixins: [abstractField],
      components: {
        StoreItem,
      },
      methods: {
        addItem() {
            const newItem = JSON.parse(JSON.stringify(this.schema.default.items[0]));
            this.value.push(newItem);
        },
        validate() {
            // this.clearValidationErrors();
            // const value = this.value;
            // const validation = this.schema.validator[0];
            // for (const ref of this.$refs['price-tag-item']) {
            //     ref.touch();
            // }
            // return validation(value);
        },
        updateModel(eventData) {
          const { index, data } = eventData;
          Vue.set(this.value, index, data);
        },
        removeItem(index) {
          this.value.splice(index, 1);
        }
      }
  };
</script>
