<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <crud-form
        :schema="schema"
        :model="form"
        :path="'store-item/'+storeId"
        :form-title="$t('saveStoreItemsUpdate')"
        :redirect-route="'prev'"
      />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import CrudForm from '@/components/CrudForm';
  import fieldStoreItems from "./form-components/fieldStoreItems";
  import { schema } from './schema/store-change-log-item';

  Vue.component('fieldStoreItems', fieldStoreItems);

  export default {
    name: 'StoreChangeLogItemsAdd',
    components: {
      CrudForm,
    },
    props: {
      storeId: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        schema,
        form: {
          items: [
            {
              product: null,
              tagItemPrice: null,
              barcode: null,
              quantity: null,
              price: null,
            }
          ],
        }
      }
    },
  }
</script>

<style lang="scss">
.store-item-form-container {
  border: 1px solid blue;
}
</style>
